
@import 'custom/tools/vars';
@import 'lib/bootstrap/scss/bootstrap';
@import 'vendor/jquery.toolbar';
@import 'vendor/jquery.feedback_me';

@import 'custom/tools/mixins';

@import 'custom/base/bootstrap-icons';
@import 'custom/base/iclr-icons';

@import "custom/base/base";
@import "custom/base/typography";
@import "custom/base/helpers";
@import "custom/base/images";

@import "custom/page-structure/grid";
@import "custom/page-structure/layout";
@import "custom/page-structure/navbar-header";
@import "custom/page-structure/report-processing";
@import "custom/page-structure/sub-navbar";
@import "custom/page-structure/sidebars";
@import "custom/page-structure/footer";
@import "custom/page-structure/case-report";
@import "custom/page-structure/justis-transcript";
@import "custom/page-structure/sections";

@import "custom/ui-components/badges";
@import "custom/ui-components/search-form";
@import "custom/ui-components/index-card";
@import "custom/ui-components/card";
@import "custom/ui-components/similarity-progress";
@import "custom/ui-components/buttons";
@import "custom/ui-components/list";
@import "custom/ui-components/forms";
@import "custom/ui-components/toolbar";
@import "custom/ui-components/content";
@import "custom/ui-components/other";
@import "custom/ui-components/tabs";
@import "custom/ui-components/pagination";
@import "custom/ui-components/synonyms";
@import "custom/ui-components/typeahead";
@import "custom/ui-components/dropdowns";
@import "custom/ui-components/case-report";
@import "custom/ui-components/alerts";
@import "custom/ui-components/modal";
@import "custom/ui-components/free-trial";
@import "custom/ui-components/paragraphSelector";
@import "custom/ui-components/notification-container";

@import "custom/ui-components/search-results.scss";

@import "custom/ui-components/help";

@import "custom/ui-components/toastr";
@import "lib/toastr/toastr";
@import "lib/select2/css/select2";

@import "custom/base/print";

@import "custom/page-structure/genie";