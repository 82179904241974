
.iclr_secondary_navigation {
  position: fixed;
  top: $header-height;
}

.sub-navbar {
  position: fixed;
  top: $header-height;
  height: $sub-header-height;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.1);
  padding: 20px 0;

  .navbar-toggler {
    color: black;

    &:hover {
      color: $hover-icon-color;
    }
  }

  @media only screen and (max-width: $mobile-screen-threshold) {
    .navbar-collapse.show, .navbar-collapse.showing, .navbar-collapse.collapsing {
      padding: 10px 0 0 0;
      border-radius: 0.64rem;
      background-color: $nav-bar-background;
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

      max-height: calc(100vh - #{$layout-padding-with-sub-nav} + #{$basic-vertical-offset});
      overflow-y: auto;
      z-index: -1;

      position: absolute;
      left: 0;
      top: 70px;
      width: 100%;

      > * {
        * {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        padding: 0.8rem 1.6rem;
        width: 100%;
        border-radius: 0.64rem;
        margin-left: 0 !important;
        margin-right: auto;
        background-color: $nav-bar-background;
      }
    }
  }

  @include respond-to(small-screens) {
    height: auto;
    position: relative;
    top: auto;
    //margin-top: 66px;
    padding: 15px 0;

    &.beta-view {
      flex-direction: column;
    }

    > .sub-navbar-part {
      width: 100%;
      margin-top: 1rem;
    }

    .list {
      margin: 0;
    }
  }
}

#sub-navbar-main {
	min-width: 0;
	flex-grow: 1;
}