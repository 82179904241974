
.twitter-typeahead {
	display: block !important;
	width: 100%;

	> pre {
		font-size: 1.4rem !important;
	}
}

.typehead {
	&.tt-hint {
		font-size: 1.4rem;
		display: none;
	}
	&.tt-input {
		font-size: 1.6rem;
	}
}

.tt-menu {
	background-color: #fff;
	border: $base-border;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,.2);

	@media only screen and (min-width: $mobile-screen-threshold) {
		min-width: 496px;
	}

	.tt-suggestion {
		display: block;
		padding: 7px 15px;
		border-bottom: $base-border;
		color: #757575;
		
		&:hover {
			cursor: pointer;
		}

		&:last-child {
			border-bottom: none;
		}

		&.tt-cursor {
			background-color: $light-gray;

			.omniboxresult {
				background-color: transparent;
			}
		}
	}
}