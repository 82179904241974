
html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1 {
  font-size: 3.4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 1.8rem;
}

body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: $base-color;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: $base-font-size;
  line-height: $base-line-height;
}

html body {
	@include avenir-light-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway";
  font-weight: $font-weight-regular;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  font-weight: $font-weight-regular;
  //color: $base-color;
  line-height: $base-line-height;
  font-size: 1.4rem;

  margin: 0 0 $small-spacing;
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

b,
strong {
  @include avenir-heavy-text;
}

//styleguides headings

.heading {
  font-weight: $font-weight-regular;
  line-height: $base-line-height;

  &--type-1 {
    font-size: 2rem;
  }

  &--type-2 {
    font-size: 1.8rem;
    @include avenir-medium-text;
  }

  &--type-3 {
    font-size: 1.6rem;
  }

  &--type-4 {
    font-size: 1.4rem;
  }

  &--type-5 {
    font-size: 1.2rem;
  }

  &--bordered {
    @include avenir-medium-text;
    border-bottom: $base-border;
    padding-bottom: .2em;
    margin-bottom: 1em;
  }

  &--medium {
	  @include avenir-medium-text;
  }

  &__link {
    color: $basic-link-color;
    text-decoration: underline;
  }

  a {
    color: $base-color;
  }
}

//styleguide paragraphs

.paragraph {
  font-weight: $font-weight-regular;
  line-height: $base-line-height;

  &--type-1 {
    font-size: 1.4rem;
  }

  &--type-2 {
    font-size: 1.3rem;
  }

  &--type-3 {
    font-size: 1.2rem;
  }

  &--type-4 {
    font-size: 1rem;
  }

  &__link {
    color: $blue;
    text-decoration: underline;
  }
}

.h-group {
  margin: 0;
  padding: 0 .5rem;
  width: calc(100% - 18px);
}

.small-text {
	font-size: 1.2rem;

	p {
		font-size: 1.2rem;
	}
}

// search paragraphs
.search-results {
	p.paragraph {
		color: $base-color;
		
		span.highlight {
			padding: 0.25rem;
			line-height: 1rem;
          // 8e38a5
			background-color: rgba(255,191,0,0.25);
			color: $base-color;
		}
	}
	
	.searchSnippets a {
		color: $base-color;
		
		&:hover {
			color: $base-color;
			text-decoration: none;
		}
	}
	
	a.btn-collapse {
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
	}
}

.pseudo-link {
	cursor: pointer;

	&:hover {
		color: $purple;

		.icon {
			color: $purple;
		}
	}
}

.bailii {
	text-transform: uppercase;
}

a.externalLink {
  color: $external-link-color;

  &:hover,
  &:active,
  &:focus {
    color: $external-link-color;
    outline: none;
    text-decoration: underline;
  }

  .external-link-icon {
    padding-inline-start: 0.5rem;
    user-select: none;

    * {
      user-select: none;
    }
  }
}

.spinner-central {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.form-select {
  font-size: 1.4rem;
}