
.ships .ship, .jurisdictions .jurisdiction, .additionalNames .additionalName {
  font-weight: 300;
}

.jurisdictions .jurisdiction {
  margin-left: 2px;
}

.indexCard {
  a { color: black; }
  a.case-ref { color: $internal-link-color; }
  a.NCit { color: $internal-link-color; }
  a.externalLink { color: $external-link-color; }
}
