
// Typography
$base-font-family: "Lato", Helvetica, Arial, sans-serif;
$article-font-family: Georgia, Times, "Times New Roman", serif;
$heading-font-family: "Raleway", Helvetica, Arial, sans-serif;
$nav-font-family: "Quattrocento Sans", sans-serif;


// Font Sizes
$base-font-size: 1.4rem;
$btn-font-weight: 600;
$iclr4-button-font-weight: 700;

// Line height
$base-line-height: 1.4;
$heading-line-height: 1.2;

//font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 800;


// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors from 2021 UI refresh
$orange: #FF5722;
$red: #E91E63;
$blue: #00BCD4;
$green: #0e783b;
$purple: #b074c0; // #8e38a5;
$purple1:  #fdf6fe;
$purple50: #e3cde9;
$purple100: #d2afdb;
$purple200: #c192ce;
$purple300: #b074c0;
$purple400: #9f56b3;
$purple500: #8e38a5;
$purple600: #79308c;
$purple700: #632773;
$purple800: #4e1f5b;
$purple900: #391642;
$green50: #e0fef5;
$green100: #b7f8e0;
$green200: #8df2c9;
$green300: #61ecae;
$green400: #38e792;
$green500: #22ce72;
$green600: #18a054;
$green700: #0d7238;
$green800: #034525;
$green900: #00190a;
$red50: #ffe4ed;
$red100: #fdb7c9;
$red200: #f68aa7;
$red300: #f05b89;
$red400: #eb2e6e;
$red500: #d11448;
$red600: #a40d2e;
$red700: #760618;
$red800: #490209;
$red900: #1e0000;
$blue50: #d6f8ff;
$blue100: #aaecff;
$blue200: #7ae4ff;
$blue300: #47dfff;
$blue400: #1addff;
$blue500: #00cce6;
$blue600: #0092b4;
$blue700: #006081;
$blue800: #00354f;
$blue900: #00111e;
$orange50: #ffeedd;
$orange100: #ffcbaf;
$orange200: #ffa67e;
$orange300: #ff7c4c;
$orange400: #ff511a;
$orange500: #e64600;
$orange600: #b44200;
$orange700: #813700;
$orange800: #4f2400;
$orange900: #210d00;
$gray50: #F7FAFC;
$gray100: #EDF2F7;
$gray200: #E2E8F0;
$gray300: #CBD5E0;
$gray400: #A0AEC0;
$gray500: #718096;
$gray600: #4A5568;
$gray700: #2D3748;
$gray800: #1A202C;
$gray900: #171923;

// Colors from Styleguide
$black: #000;
$black-gray: #424242;
$dark: #1E0F00;
$base-color-dark: #171E22;
$dark-gray: #575656;
$base-color: #000;
$mid-gray: #9E9E9E;
$blue-gray: #607D8B;
$gray: #A7A9AC;
$aluminum: #E6E7E8;
$light-gray: #F3F4F5;
$green: #0E783A;
$light-green: #4CAF50;
$red: #CC0000;
$pink: #E91E63;
$orange: #F44336;
$deep-orange: #FF5722;
$light-yellow: #FFEB3B;
$yellow: #FFC107;
$lime: #CDDC39;
$dark-blue: rgb(7,71,125);
$blue: #0065AA;
$light-blue: #03a9f4;
$aqua: #00BCD4;
$glass: #009688;
$indigo: #3F51B5;
$brown: #795548;
$distinguished: #BD4B00;

$overlay-background-color: rgba(0, 0, 0, .5);

$basic-link-color: $purple400;
$hover-link-color: $purple500;
$external-link-color: $blue;
$internal-link-color: $green;
$footnote-ref-color: darken($glass, 5%);

$basic-button-color: $purple400;
$hover-button-color: $purple500;
$secondary-button-color: #fff;
$hover-secondary-button-color: $gray200;
$focus-button-color: black;
$button-text-color: white;
$secondary-button-text-color: #292b2c;

$nav-bar-background: $gray50;

$hover-nav-link-color: $gray100;
$nav-text-color: $gray600;

$basic-icon-color: $purple400;
$hover-icon-color: $purple600;
$disabled-icon-color: $gray;

$hover-checkbox-label-color: $purple400;

// Border
$base-border-color: $aluminum;
$base-border: 1px solid $base-border-color;
$focus-border-color: $gray400;

$focus-button-border-color: black;

$hover-input-border-color: $gray400;
$focus-input-border-color: $green;

$nav-link-border-color: $gray200;

// Animations
$base-duration: 300ms;
$base-timing: ease;

//sidebar
//$sidebar-width: 290px; - original

//components sizes
$header-height: 83.72px;
$logo-height: 36px;
$search-field-height: 46px;
$sub-header-height: 75px;
$avatar-size: 28px;
$footer-height: 60px;

//components colors
$header-bg: $black;
$body-bg: $light-gray;
$footer-bg: $aluminum;
$dark-btn-bg: $dark;

//layouts
$basic-vertical-offset: 20px;
$layout-padding-with-header: $header-height + $basic-vertical-offset;
$layout-padding-with-sub-nav: $layout-padding-with-header + $sub-header-height;

//sidebars
$sidebar-max-height-default: $layout-padding-with-header;
$sidebar-max-height-subnav: $layout-padding-with-sub-nav;

$input-bg: white;

$mobile-screen-threshold: 1199.98px;