//MEDIA MIXIN
$small: 767px;
$large: 1200px;

@mixin respond-to($media) {
  @if $media == small-screens {
    @media only screen and (max-width: $small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $small) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $large) {
      @content;
    }
  }
}

@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin multi-row-ellipsis($rows: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin content-item {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .24);
  // Edit 18.01.18
  // border-radius: $base-border-radius;
  border-radius:0;
  border: none;
}

@mixin scroll-y {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin layout {
  //@include scroll-y;
  background-color: rgb(246, 247, 251);
}

@mixin container {
  width: 100%;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 auto;
}

@mixin avenir-heavy-text {
	font-weight: 700;
}

@mixin avenir-medium-text {
	font-weight: 500;
}

@mixin avenir-roman-text {
	font-weight: 400;
}

@mixin avenir-light-text {
	font-weight: 300;
}