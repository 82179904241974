
.action-menu {
	//text-align: right;
	display: flex;
	justify-content: flex-end;

	&__item {
		margin-left: 10px;

		.btn-icon {
			color: $dark-gray;
		}
	}
}

.status {
	position: relative;
	padding-left: 20px;
	margin-bottom: 1rem;

	&--applied {
		.status__mark {
			.icon {
				color: #2a7e00;
			}
		}
		.status__entity {
			border-left-color: #2a7e00;

			span.consideration {
				color: #2a7e00;
			}
		}
	}

	&--not {
		.status__mark {
			.icon {
				color: $red;
			}
		}

		.status__entity {
			border-left-color: $red;

			span.consideration {
				color: $red;
			}
		}
	}

	&--considered {
		.status__mark {
			.icon {
				color: #005a88;
			}
		}

		.status__entity {
			border-left-color: #005a88;

			span.consideration {
				color: #005a88;
			}
		}
	}
	
	&--distinguished {
		.status__mark {
			.icon {
				color: $distinguished;
			}
		}

		.status__entity {
			border-left-color: $distinguished;

			span.consideration {
				color: $distinguished;
			}
		}
	}

	&--staged {
		&__stage {
			&--completed {}

			&--incomplete {}
		}
	}

	&__mark {
		position: absolute;
		top: 0;
		left: 0;
		width: 5px;
		line-height: 12px;

		.icon {
			font-size: 1rem;
			margin: 0;
		}
	}

	&__entity {
		padding-left: 10px;
		border-left: 2px solid $gray;
	}
}

.badge-default {
	background-color: $dark-gray;
	color: white;
}

.collapse-wrap {
	padding: 0 0 1rem 1.85rem;
}

.history-item {
	display: flex;
	align-items: stretch;
	margin-bottom: 2.5rem;

	p {
		> i {
			padding-inline-end: 0.25rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__line {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 1px;
		margin-block: 0.45rem;
		background-color: $light-green;
		z-index: 2;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			z-index: 2;
			background-color: $light-green;
		}

		&.affirmed {
			background-color: #2a7e00;
			&:before, &:after { background-color: #2a7e00 }
		}
		&.restored {
			background-color: #2a7e00;
			&:before, &:after { background-color: #2a7e00 }
		}
		&.reversed {
			background-color: $red;
			&:before, &:after { background-color: $red }
		}
		&.varied {
			background-color: $distinguished;
			&:before, &:after { background-color: $distinguished }
		}
	}

	.considerationType {
		&.affirmed { color: #2a7e00;   }
		&.restored { color: #2a7e00;   }
		&.reversed { color: $red;           }
		&.varied   { color: $distinguished; }
	}

	> div {
		margin-inline: 1rem;
	}

}

.browse-comment {
	color: $dark-gray;
}

.export-state-messages {
	font-family: "Courier New", monospace;
}

.text-transform-none {
	text-transform: none !important;
}

.skip-links {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	left: -9999px;
	z-index: 999;
	padding: 1em;
	margin-top: 5px;
	border: 2px solid $gray;
	opacity: 0;
	background-color: $gray50;

	&:focus-within {
		left: 50%;
		transform: translateX(-50%);
		opacity: 1;
	}
}

.select2-container--default .select2-selection--single {
	&:hover {
		border-color: $dark-gray;
	}
}

.select2-search {
	input:focus {
		border-color: $focus-input-border-color !important;
	}
}

#otherBox #otherInputBox {
	border: $base-border;

	&:hover {
		border-color: $hover-input-border-color;
	}

	&:focus {
		border-color: $focus-input-border-color;
	}
}

#CaseReport *:target:focus {
	background-color: transparentize($purple50, 0.7);
	outline: 1px $purple500 solid;

	&:not(.CatchWords):not(#curia-catchwords):not(#curia-operative-part):not(#curia-representation):not(#curia-judgement) {
		padding-inline: 3px;
		margin-inline: -3px;
	}

	&.CatchWords {
		padding-inline: 3.5em 0.5em;
		margin-inline: -0.5em -0.5em;
	}
}

.indexCard *:target:focus {
	background-color: transparentize($purple50, 0.7);
	outline: 1px $purple500 solid;
	padding-inline: 1rem;
	margin-inline: -1rem;
}