
.nav-tabs {
  border: none;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item {
  border: none;
  color: $base-color;

  &:hover {
    color: $basic-link-color;
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: $hover-link-color;
  color: #fff;
}