.search-form {
	margin: 0 auto;
	display: flex;

	@include respond-to(small-screens){
		flex-wrap: wrap;
	}

	&__links {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		flex: 1 1 30%;
		padding-left: 10px;

		@include respond-to(small-screens){
			padding: 15px 0 0 0;
			flex: 0 0 100%;
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__link-item {
		display: block;
		font-size: 1.4rem;
		white-space: nowrap;

		.icon {
			font-size: inherit;
		}
	}

	&__input {
		position: relative;
		flex: 1 1 70%;

		@include respond-to(small-screens){
			flex: 0 0 100%;
		}

		.form-control {
			border-radius: $base-border-radius;
			border-color: $aluminum;
			display: block;
			height: $search-field-height;
			padding: 1.2rem 2rem .8rem 3.5rem;
			font-size: 1.8rem;
			color: $base-color;
			font-weight: $font-weight-light;
			letter-spacing: 0.25px;

			&::placeholder {
				color: $gray;
			}
		}

		.icon-search {
			position: absolute;
			top: 14px;
			left: 11px;
			font-size: 1.6rem;
			color: $gray;
			z-index: 1;
		}
	}

	&__button {
		border: none;
		background: transparent;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 5;
		bottom: 0;
		padding: 10px;

		&:hover .icon {
			color: $hover-icon-color;
		}
		&:focus {
			outline: 1px solid $focus-border-color;
			outline-offset: -4px;
		}

		.icon {
			font-size: 1.6rem;
			color: $gray;
		}
	}

	//in header

	.navbar-header & {
		margin: 0;
		flex: 1 1 auto;

		&__input {
			display: flex;
			align-items: center;

			form {
				width: 100%;
			}

			.form-control {
				border: none;
				height: 34px;
				font-size: $base-font-size;
				padding: .5rem 2rem .5rem 3.5rem;
			}

			.icon-search {
				font-size: 1.4rem;
				top: 7px;
			}
		}

		&__link-item {
			color: #fff;

			&:hover {
				color: $basic-link-color;
			}
		}

		.search-form__button {
			@include respond-to(small-screens){
				padding: 6px;
			}

			width: 34px;

			.icon {
				position: absolute;
				right: 9px;
				top: 6px;
			}
		}
	}
}

.search-results-container #advancedSearchForm > .row {
	padding: 10px 0 5px 0;
	border: solid 1px $aluminum
}