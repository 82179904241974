@media print {
		
	.navbar,
	.sub-navbar,
	.sidebar,
	.page-footer,
	.tool-container,
	.tool-items,
	a.tool-item,
	.layout--subnav
	.feedback_trigger,
	.feedback_content,
	.cookies,
	#cookie-bar,
	.search-form,
	.help,
	.help-icon-button,
	.btn-collapse,
	.fa-sr-only,
	.sr-only,
	.history-item__line
	{
		display: none;
	}
	
	.layout--subnav {
		padding: 0;
	}

	// 67B override styles for print	
	
		a[href]:after { content: none; } //turn off the links that are displayed in print
	
	    //bootstrap tries to take away our colours in print, this puts them back again
	    .considerationType_002 .consideration { color: green !important; }  /* Affirmed     */
	    .considerationType_030 .consideration { color: red !important; }    /* Not Followed */
	    .considerationType_060 .consideration { color: red !important; }    /* Not Applied  */
	    .considerationType_110 .consideration { color: green !important; }  /* Applied      */
	    .considerationType_120 .consideration { color: blue !important; }   /* Considered   */
	
	    .watermark{ display: block; }
	    .downloadLink{ display: none; }
	    .print-only { display: block; }
}