
.alert {
	font-size: 1.4rem;
	border: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

	.icon {
		font-size: 1rem;
		margin-right: 5px;
	}

	&.alert-success {
		background-color: #fff;
		color: $green;
	}
}
