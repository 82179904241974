.toolbar {
  display: none;
  background: white;

  &__option {
    color: $black-gray;
    background: white;
    padding: 1rem 1.75rem;
    width: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $aluminum;

    .icon {
      font-size: inherit;
    }

    &:hover {
      background-color: $basic-link-color;
      color: #fff;
      text-decoration: none;
    }
  }
}