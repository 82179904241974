.layout {
  padding-top: $layout-padding-with-header;
  padding-bottom: $basic-vertical-offset;
  min-height: calc(100vh - #{$footer-height});

  @include respond-to(small-screens) {
  }

  &--default {
    // Edit 18.01.18
    //background-color: $light-gray;
    background-color: #f5f5f5;
  }

  &--white {
    background-color: #fff;
  }

  &--sidebars {
    //padding-left: 250px;
    //padding-right: 250px;
  }

  &--subnav {
    padding-top: #{$layout-padding-with-sub-nav};
  }
}

html {
  scroll-padding-top: $layout-padding-with-sub-nav;
}