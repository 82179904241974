
.toast-top-full-width {
	top: 94px;
	right: 30px;
}

#toast-container {
	pointer-events: none;
	.toast {
		pointer-events: auto;
		color: #fff !important;
		position: relative;
		overflow: hidden;
		border-radius: 3px;
		box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24);
		opacity: 1;
		padding: 15px;
		width: auto;
		max-width: 500px;

		&.toast-info,
		&.toast-success {
			background-color: $green;
		}

		.toast-text {
			.toast-title {
				vertical-align: middle;
				font-size: 1.6rem;
				font-weight: $font-weight-regular;
			}
		}

		.toast-close-button {
			position: absolute;
			right: 12px;
			top: 10px;
		}

		.toast-message {
			margin: 0 15px 0 30px;
			font-weight: $font-weight-bold;
			font-size: 14px;
		}
	}
}
