
.cards-container {
	.card {
		.heading {
			@include multi-row-ellipsis(2);
		}

		.paragraph {
			@include multi-row-ellipsis(6);
			max-height: 155px;
			margin-bottom: 0;
		}
		&:hover {
			box-shadow: 0 4px 9px rgba(0, 0, 0, 0.24);
		}
	}

	a.card__body-link {
		&:hover,
		&:active,
		&:focus {
			color: $base-color;
			text-decoration: none;

			.heading {
				text-decoration: underline;
			}
		}
		&:focus {
			padding: 8px;
			margin: 1px;
			border: 1px solid $focus-border-color;
		}
	}
}

.card {
	@include content-item;
	margin: 0 10px 20px;
	height: 175px;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	@include respond-to(small-screens){
		height: auto;
	}

	&.paragraphSimilarity {
		background-color: $purple1;
		border: solid 1px $purple500;
		border-radius: 0.52rem;
		margin: 5px 5px 0 5px;

		svg { font-size: 3.5rem; color: $purple500; margin-right: 5px; top: 5px; position: relative; float:right; }
		.help { margin-left: 5px; }

		.card__body { padding: 10px;
			p { font-size: 1.4rem; }
		}
	}

	&.card--similar-paragraph > .card__body {
		.closeParaToggle {
			width: 100%;
			text-align: center;
			margin: -10px auto 0;
			height: 30px;

			&.hovering {
				.closePara {
					color: black;
				}
			}

			.closePara {
				font-size: 30pt;
				cursor: pointer;
				color: $dark-gray;
			}
			.closeParaHint {
				font-size: 0.825em;
			}
		}

		a {
			color: $hover-link-color; //Accessible contrast for the gray background
		}
	}

	&--size-md {
		float: left;

		@include respond-to(small-screens){
			width: calc(100% - 20px);
		}

		@include respond-to(medium-screens){
			width: calc(50% - 20px);
		}

		@include respond-to(wide-screens){
			width: calc(33.3333% - 20px);
		}
	}

	&--size-sm {
		height: 110px;
		float: left;

		@include respond-to(small-screens){
			width: calc(50% - 20px);
		}

		@include respond-to(medium-screens){
			width: calc(33% - 20px);
		}

		@include respond-to(wide-screens){
			width: calc(25% - 20px);
		}
	}

	&--similar-paragraph {
		height: auto;
		margin: 6px 0 0 0;
		background-color: #e2e6ec;
		p {
			padding: 0 !important;
		}
	}

	&__body-link {
		position: relative;
		display: block;
		color: $base-color;
		padding: 10px;
		flex-grow: 1;
		text-decoration: none;

		.heading {
			color: $black-gray;
		}

		p {
			color: $black-gray;
			line-height: 1.5;
		}

		.sidebar & {
			padding: 15px;
		}
	}

	&__body {
		position: relative;
		padding: 10px;
		flex-grow: 1;

		.sidebar & {
			padding: 15px;
		}
	}

	&__body_left {
		text-align: left;
	}

	&__title {}

	&__description {}

	&__footer {
		display: flex;
		justify-content: space-between;
		border-top: $base-border;
		padding: 5px 10px;
		flex: 0 0 30px;
		font-size: 1.2rem;
		line-height: 1.7;

		.sidebar & {
			padding: 5px 15px;
		}
	}

	&__tag {
		@include avenir-roman-text;
		@include ellipsis;
		color: $base-color;
		max-width: calc(100% - 85px);

		&:hover {
			color: $base-color;
			text-decoration: underline;
		}

		&:active {
			color: $base-color;
			text-decoration: none;
		}
	}

	&__label {
		position: absolute;
		bottom: 10px;
		left: 10px;
	}

	&__date {
		color: $dark-gray;

		.icon {
			font-size: inherit;
			margin-right: 2px;
		}
	}

	&--topic-family {
		border-top: 5px solid $light-green;
	}

	&--topic-commercial {
		border-top: 5px solid $aqua;
	}

	&--topic-ip-media {
		border-top: 5px solid $lime;
	}

	&--topic-crime {
		border-top: 5px solid $pink;
	}

	&--topic-employment {
		border-top: 5px solid $yellow;
	}

	&--topic-european-union {
		border-top: 5px solid $light-yellow;
	}

	&--topic-land-law {
		border-top: 5px solid $brown;
	}

	&--topic-civil-litigation {
		border-top: 5px solid $indigo;
	}

	&--topic-costs {
		border-top: 5px solid $mid-gray;
	}

	&--topic-public-law {
		border-top: 5px solid $deep-orange;
	}

	&--topic-tort {
		border-top: 5px solid $glass;
	}

	&--topic-ecclesiastical {
		border-top: 5px solid $orange;
	}

	&--topic-tax {
		border-top: 5px solid $blue-gray;
	}

	&--topic-trusts-chancery {
		border-top: 5px solid $purple;
	}

	&--post {
		height: auto;
		color: #fff;
	}

	&--post-1 {

		.card__body {
			background-image: url("assets/images/content/blog-post-1-bg.jpg");
			background-size: cover;
			background-position: center;
			padding: 0 0 150%;
		}

		.card__label {
			color: #fff;
		}
	}
}