
.list {
  @include respond-to(small-screens){
    margin-bottom: $basic-vertical-offset;
  }

  &__item {
  	font-size:1.3rem;
  }

  &__link {
    display: block;
    color: $base-color;
    padding: 0;

    &:hover {
      color: $basic-link-color;

      .icon {
        color: $basic-link-color;
      }
    }
  }

  .icon {
    font-size: inherit;
    vertical-align: middle;
    margin-right: 2px;
    color: $gray;
  }

  &--separeted {
    .list__item {
      border-bottom: $base-border;
    }

    .list__link {
      padding: 0.7rem 1.5rem;
      font-size: 1.4rem;

      &:hover {
        background-color: $basic-link-color;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &--muted {
    .list__link {
      color: $gray;
    }
  }

  &--complex {
    .list__item {
      margin-bottom: 10px;
    }

    .list__link {
      display: flex;
    }

    .list__item-thumb {
      flex: 0 0 45px;
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }

    .list__item-title {
      @include multi-row-ellipsis(3);
      line-height: 1.3;
      max-width: calc(100% - 55px);
      flex: 0 0 calc(100% - 55px);
    }
  }

}

.list-contact li.list__item a.list__link {
	line-height: 2.5rem;
	text-decoration: none;
}

.list-table li.list__item:first-child a.list__link {
	border-top: 1px solid #eceeef;
}
.list-table li.list__item a.list__link {
	border-bottom: 1px solid #eceeef;
	font-size: 1.4rem;
	padding: 0.7rem;
}