
button, input, optgroup, select, textarea {
	font-family: $base-font-family;
}

button {
	font-weight: $btn-font-weight;
}

.form-check {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;

	.form-check-label {
		float: none;
		padding-inline-start: 0.85rem;
	}

	.form-check-input {
		float: none;
		margin: 0;
		border-radius: $border-radius;

		&[type='radio'] {
			border-radius: 50%;
		}

		&:checked {
			background-color: $blue;
			border-color: $blue;
		}

		&:not(:disabled) {
			cursor: pointer;

			&:hover {
				border-color: $focus-border-color;

				+ .form-check-label {
					color: $hover-checkbox-label-color;
				}
			}

			+ .form-check-label {
				cursor: pointer;

				&:hover {
					color: $hover-checkbox-label-color;
				}
			}
		}
	}

	&:focus-within {
		outline: 1px solid $focus-border-color;
		outline-offset: 2px;
	}
}

.form-select {
	padding: .75rem;

	&:hover:not(:disabled) {
		border-color: $hover-input-border-color;
	}

	&:focus {
		border-color: $focus-input-border-color;
		box-shadow: none;
	}
}

.form-control {
	@include avenir-light-text;
	font-size: 1.4rem;
	padding: .75rem;
	border-radius: $base-border-radius;
	border-color: $base-border-color;

	&::placeholder {
		color: $gray;
	}

	&:hover:not(:disabled) {
		border-color: $hover-input-border-color;
	}

	&:focus {
		border-color: $focus-input-border-color;
		box-shadow: none;
	}

	@at-root select#{&}{
		height: 34px !important;
	}
}

input[type='date'] {
	height: 34px;
}

.col-form-label {
	line-height: 2;
	padding-right: 0;
}

.form-horizontal {
	.col-form-label {
		text-align: right;

		@include respond-to(small-screens){
			text-align: left;
		}
	}
}

dl.field-description {
	dt { display:inline; }
	dd::before { content: "— " }
	dd { display:inline }
	dd::after { content: " "; display: block; clear: left; }

}

.form-group {
	margin-block-end: 0.25rem;
}

.form-label {
	margin-block-end: 0.125rem;
}

.form-text {
	margin-block-start: 0.125rem;
	margin-block-end: 0.125rem;
	color: lighten(black, 30);
	font-size: 0.85em;
}

.input-group.date .form-control:not(:disabled) + .input-group-append:hover {
	margin-left: 0 !important;

	 .input-group-text {
		border-color: $hover-input-border-color;
	}
}