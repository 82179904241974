.searchExample { color: $green }
.searchSyntax { color: $dark-blue }
.searchSingleTerm { font-weight: bold; }
.searchConstraintName { font-style: italic; }
.searchMatch { font-weight: bold; }

.help-page {
  h2 { margin-top: 40px; }
  p, ul { font-size: 1.6rem; }

  ul.list {
    list-style-type: disc;
    margin: 0 0 10px 0;
    padding: 0 0 0 20px;
    li {
      margin-left: 20px;
    }
  }
}

ul.searchMatches {
  margin-bottom: 10px;
  li {
    margin-left: 30px;
  }
}

.faq-question {
  margin-bottom: 0;
}

.faq-answer {
  margin-top: 0;
}


.help-popover {
  font-size: 1.4rem;

  .popover-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

h3.heading .help-icon-button .icon-help-circle {
  vertical-align: text-bottom;
}

.help-icon-button {
  color: transparent;
  background-color: transparent;
  border: 1px solid transparent;
  font-weight: normal;

  > .icon-help-circle {
    vertical-align: text-top;
  }

  &:hover {
    > .icon-help-circle {
      color: $hover-icon-color;
    }
  }
  &:focus {
    border-color: $focus-border-color;
  }
}

.popover[data-popper-placement^='top'] > .help-popover-arrow {
  bottom: -5px;
  &::before {
    border: rgba(0, 0, 0, 0.2) solid;
    border-width: 0 1px 1px 0;
  }
}

.popover[data-popper-placement^='bottom'] > .help-popover-arrow {
  top: -5px;
  &::before {
    border: rgba(0, 0, 0, 0.2) solid;
    border-width: 1px 0 0 1px;
    background-color: #f0f0f0;
  }
}

.popover[data-popper-placement^='left'] > .help-popover-arrow {
  right: -5px;
  &::before {
    border: rgba(0, 0, 0, 0.2) solid;
    border-width: 1px 1px 0 0;
  }
}

.popover[data-popper-placement^='right'] > .help-popover-arrow {
  left: -5px;
  &::before {
    border: rgba(0, 0, 0, 0.2) solid;
    border-width: 0 0 1px 1px;
  }
}

.help-popover-arrow,
.help-popover-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.help-popover-arrow {
  visibility: hidden;
}

.help-popover-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}