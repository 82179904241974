#CaseReport .para-block.p-selected {
  border-color: #b2bed1;
  border-style: dashed;
  margin: -6px;
  padding: 3px;
}

.parablock {
  margin-right: 5px;
  margin-top: 5px;

  .closePara {
    position: absolute;
    right: 30px;
    top: 5px;
    font-size: 15pt;
  }

}
.narrow-toc{
  max-width: 15%;
}


