
#CaseReport .justis-transcript {

  p { margin: 0 }

  .caseno { width: 100%; text-align: right; }
  div.ncitation p, div.date p span { font-weight: bold; font-size: 110%; }
  div.date p { margin: 0; padding: 0 }
  div.court {
    width: 100%; text-align: right;
    p { padding: 0; margin: 0; line-height: normal; }
  }
  div.judges, div.casename, div.counsel, div.information { width: 100%; text-align: center; }
  div.casename { margin: 0 0 20px 0; }
  div.casename p { margin: 0; padding: 0;  }
  div.judges p, div.judges div, div.counsel p { padding: 0; margin: 0;  }
  div.party, div.party span, div.versus { padding: 0; margin: 0; line-height: normal; font-weight: bold; font-size: 110% }
  div.information {
    p { line-height: normal; margin: 0; padding: 0; color: #555; }
    .style { font-style: italic; }
  }

  span.bold, span.link { font-weight: bold }
  span.italic { font-style: italic }
  span.superscript { vertical-align: super; font-size: smaller; }

  div.status { font-weight: normal !important; margin: 0; padding: 0; }

  span.fraction { font-size: smaller; }

  span.underline { text-decoration: underline }
  span.italic { font-style: italic }
  span.bold { font-weight: bold }
}