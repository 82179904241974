.collapseSidebar.navbar-toggler {
	color: black;
	height: 2.5rem;
	width: 2.5rem;

	.icon {
		font-size: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		width: initial;
		height: initial;
	}
	&:not(.collapsed) .icon.closed {
		display: none;
		visibility: hidden;
	}
	&.collapsed .icon.open {
		display: none;
		visibility: hidden;
	}

	&:hover {
		color: purple;
	}
}

.btn-container {
	display: flex;
	flex-flow: wrap;
	gap: 12px;
}

.btn {
	font-size: 1.4rem;
	border: 1px solid transparent;
	outline: none;
	cursor: pointer;
	z-index: 2;
	border-radius: $base-border-radius;

	.icon {
		font-size: inherit;
		vertical-align: middle;
	}

	&[disabled] {
		background-color: $aluminum !important;
		color: $gray !important;
		opacity: 1 !important;

		&:hover {
			cursor: not-allowed;
		}
	}

	&:focus, &.focus {
		outline: none;
		box-shadow: none;
	}
}

.feedback_submit,
.btn-dark {
	background-color: $basic-button-color;
	color: $button-text-color;
	border: none;

	&:hover:not(.disabled) {
		background-color: $hover-button-color;
		color: $button-text-color;
		border: none;
	}
	&:focus:not(.disabled) {
		background-color: $basic-button-color;
		color: $button-text-color;
		border: none;
		outline: 2px solid $focus-button-border-color;
	}
}
.btn-dark.disabled {
	cursor: default;
}

.btn-primary {
	background-color: $blue;
	color: $button-text-color;

	&:hover {
		background-color: $dark-blue;
		color: $button-text-color;
	}

	&:focus {
		background-color: $blue;
		color: $button-text-color;
		outline: 2px solid $focus-button-border-color;
	}
}

.btn-secondary {
	color: $secondary-button-text-color;
	background-color: $secondary-button-color;

	&:hover {
		color: $secondary-button-text-color;
		background-color: $hover-secondary-button-color;
		border-color: $hover-secondary-button-color;
	}

	&:active,
	&:focus, &.focus {
		border-color: $focus-border-color;
		color: $secondary-button-text-color;
		background-color: $secondary-button-color;
	}
}

.btn-success {
	background-color: $green700;
	color: $button-text-color;

	&:hover {
		background-color: $green800;
		color: $button-text-color;
	}

	&:focus {
		background-color: $green700;
		color: $button-text-color;
		outline: 2px solid $focus-button-border-color;
	}
}
.btn-danger {
	&:focus {
		outline: 2px solid $focus-button-border-color;
		color: $button-text-color;
	}
}

.btn-fat {
	font-size: 1.4rem;
	padding: 1.2rem 2rem;
}
.btn-thin {
	padding-top: 3px;
	padding-bottom: 3px;
}

.btn-dropdown {
	color: $base-color !important;
	background-color: $light-gray;
	border-radius: 0;
	width: 100%;
	text-align: left;

	&:hover {
		background-color: $aluminum !important;
	}
}

.btn-collapse {
	color: $base-color;

	&:hover .icon {
		color: $hover-icon-color;
	}

	&:active,
	&:focus {
		outline: 1px solid $focus-border-color;
		outline-offset: 1px;
	}

	.icon {
		margin-right: .5rem;
		font-size: 1.4rem;
	}

	.icon-expand {
		display: none;
	}

	&.collapsed {
		.icon-expand {
			display: inline-block;
		}

		.icon-contract {
			display: none;
		}
	}
}

.btn-row-wrap {
	position: relative;
	text-align: center;

	&:after {
		content: "";
		display: block;
		left: 0;
		right: 0;
		top: 50%;
		height: 1px;
		position: absolute;
		z-index: 1;
		background-color: $gray;
	}

	.btn {
		position: relative;
		z-index: 2;
		border-radius: 20px;
	}
}

.navbar-toggler .navbar-toggler-icon {
	width: 100%;
	height: 100%;
	font-size: 2rem;
}

.iclr4_button {
	background: $basic-button-color;
	color: $button-text-color;
	border: 2px solid transparent;
	margin: 0;
	text-decoration: none;
	font-weight: $iclr4-button-font-weight;
	line-height: 1;
	display: block;
	border-radius: 0.52rem;
	font-size: 14px;
	padding: 1rem;
	text-align: center;

	& span {
		padding-right: 0.8rem;
		font-size:  14px;
		position: relative;
		top: 0;
	}

	&.white-background {
		color: $black;
	}

	&:hover {
		text-decoration: none;
		color: $button-text-color;
		background: $hover-button-color;

		&.white-background {
			color: $black;
			background-color: $hover-nav-link-color !important;
		}
	}
	&:focus {
		text-decoration: none;
		color: $button-text-color;
		border-color: $focus-button-border-color;

		&.bordered-dark {
			outline: 1px solid darken($focus-border-color, 20%);
			outline-offset: 2px;
		}

		&.white-background {
			color: $black;
			outline: 1px solid $focus-border-color;
			outline-offset: 2px;
		}
	}
}

#personaliseFeedBtn {
	float: right;
	color: black;

	&:hover {
		color: purple;
	}
	&:focus svg {
		outline: 1px solid $focus-border-color;
		outline-offset: 2px;
	}
}

