
#CaseReport {
    //padding-top: $layout-padding-with-sub-nav !important;
}

.case-report {
  @media only screen and (max-width: $mobile-screen-threshold) {
    margin: 0 10px 0 10px !important;

    &.withPageNumbers {
      margin: 0 10px 0 35px !important;
    }
  }

  font-family: $article-font-family;
  font-size: 1.6rem;
  color: $black;
  padding:0 !important;

    u {
        text-decoration: none;
    }

    p {
        font-size: 1.5rem;
        line-height: 2.75rem;
    }

    span {
        font-size: 1.5rem;
        line-height: 2.75rem;
    }

  .CaseReport {
    font: 1.6rem $article-font-family;
    color: $black;
    line-height: 2.5rem;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;

    > div { position: relative; }

    .Metadata {
        //padding: 2em 0;
        font-size: 1rem;
        font-family: $base-font-family;
        
        .Series, .ICLRPublication {
            font-size: 1.3rem;
        }
    }

    .CaseHeader {
        padding-bottom: 3em;
        margin-bottom: 3em;
        text-align: center;
        border-bottom: 1px solid $aluminum;
        
        .Court {
            display: block;
            padding-bottom: 1em;
            font-size: 0.8em;
        }
        .Case span {
            padding-bottom: 1em;
            font-size: 3rem;
            //line-height: 1.5em;
            .party {}
        }
        .HearingDates {
            padding-bottom: 1em;
                
            .HearingDate {}
        }
        .Bench {
            .Judge {}
        }
    }
    
    .CatchWords {
        padding-bottom: 1em;
        font-style: italic;
        text-indent: -3em;
        padding-left: 3em;
        text-align: justify;
        
        .CatchWord {}
    }
    
    .HeadNote {
        text-align: justify;
        
        .Held {}
    }
    
    .Facts {
        text-align: justify;
        
        .Fact {}
    }
    
    .Appearances {
        text-align: justify;
    }
    
    .Judgments {
        text-align: justify;
        
        .Judgment {}
    }
    
    .Solicitors {
        text-align: justify;
        }
    
    .Reporter {
        text-align: justify;
        }
    }

    .section {
        h1,
        h2,
        h3 {
            display: inline-block;
            vertical-align: middle;

            .num {
                margin-right: 10px;
            }
        }

        .subsection {
            padding-left: 20px;
        }
    }
}

.shortTitle {
    text-align: center;
}

.longTitle,
.longTitle p {
    font-size: 3rem;
    text-align: center;
    padding-bottom: 1rem;
    text-transform: uppercase;
}

.DateofEnactment {

}

.anchor {
    //display: block;
}

mark {
    background-color: rgba(14, 120, 58, 0.25);

    &.current {
        background-color: rgba(14, 120, 58, 0.37);
    }

    .nomark & { background-color: inherit; }
}