.clearfix {
	@include clearfix;
}

.info-text {
	color: $dark-blue !important;
}

.primary-text {
	color: $blue !important;
}

.success-text {
	color: $green !important;
}

.warning-text {
	color: $orange !important;
}

.alert-text {
	color: $red !important;
}

.muted-text {
	color: $gray !important;
}

.base-text {
	color: $base-color !important;
}

.avenir-heavy {
	@include avenir-heavy-text;
}

.avenir-medium {
	@include avenir-medium-text;
}

.avenir-roman {
	@include avenir-roman-text;
}

.avenir-light {
	@include avenir-light-text;
}

.table-view {
	display: table;
	width: 100%;

	&.__fixed {
		table-layout: fixed;
	}

	.table-cell-view {
		display: table-cell;
	}
}

.fluid-table {
	width: 100%;
	overflow-x: auto;
}

.inline-block {
	display: inline-block;
}

.block {
	display: block;
}

.relative {
	position: relative;
}

.elastic-text-row {
	word-wrap: break-word;
}

.solid-text-row {
	white-space: nowrap;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden;
}

.ptb-10 {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.ptb-20 {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.pt-5 {
	padding-top: 5px !important;
}

.pt-15 {
	padding-top: 15px !important;
}

.pb-30 {
	padding-bottom: 30px !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.ml-20 {
	margin-left: 20px !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mobile-mb-15 {
	@include respond-to(small-screens){
		margin-bottom: 15px !important;
	}
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mobile-mb-20 {
	@include respond-to(small-screens){
		margin-bottom: 20px !important;
	}
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.full-height {
	height: 100%;
}

.full-width {
	width: 100%;
}

.semi-transparent {
	opacity: .5;
}

.hidden {
	display: none !important;
}

.flex {
	display: flex;

	&--h-center {
		justify-content: center;
	}

	&--h-right {
		justify-content: flex-end;
	}

	&--h-between {
		justify-content: space-between;
	}

	&--v-center {
		align-items: center;
		align-content: center;
	}

	&--v-top {
		align-items: flex-start;
		align-content: flex-start;
	}
}

.flex-align {
	&--left {
		margin-right: auto;

		&--mobile {
			.is-mobile & {
				margin-right: auto;
			}
		}
	}

	&--right {
		margin-left: auto;
	}
}

.align-flex-start {
	align-self: flex-start;
}

.flex-grow {
	flex-grow: 1;
}

.clickable {
	cursor: pointer;
}

.no-scroll {
	overflow: hidden !important;
}

.case-lower {
	text-transform: lowercase;
}

.no-select {
	user-select: none;
}

.ellipsis {
	@include ellipsis;
}

.bordered {
	padding-bottom: .5rem;
	margin-bottom: .5rem;
	border-bottom: $base-border;
}

.highlight {
	color: $light-green;
}

.highlight-grey {
	background-color: lighten(black, 90);
}

.bordered-dark {
  border: 1px solid black !important;
}

.white-background {
  background-color: white !important;
}

.font-weight-500 {
	font-weight: 500 !important;
}