// ------------------------------------------------------------------
// Case report
// ------------------------------------------------------------------
.CaseReport {

	font: 16px $article-font-family;
	color: $base-color-dark;
	line-height: 1.5em;

	.action-menu {
		display: inline-block;
		position: absolute;
		top: 0;
		right: 0;
		//background: blue;
	}

	// Publication
	// ------------------------------------------------------------------
	.Metadata {
		//padding: 2em 0;
		font-size: 0.9em;
		font-family: $base-font-family;

		.Series {}
		.ICLRPublication {}
	}

	// Case header
	// ------------------------------------------------------------------
	.CaseHeader {
		padding-bottom: 3em;
		margin-bottom: 3em;
		text-align: center;
		border-bottom: 1px solid $aluminum;

		.Court {
			display: block;
			padding-bottom: 1em;
			font-size: 0.8em;
		}
		.Case {
			padding-bottom: 1em;
			font-size: 2em;
			line-height: 1.3em;
			.party {}
		}
		.HearingDates {
			padding-bottom: 1em;

			.HearingDate {}
		}
		.Bench {
			.Judge {}
		}
	} // end .CaseHeader

	// Catchwords
	// ------------------------------------------------------------------
	.CatchWords {
		padding-bottom: 1em;
		font-style: italic;

		// Indent paragraph
		text-indent: -3em;
		padding-left: 3em;

		span.CatchWord {}
	}

	.blockquote {
		border-left: .25rem solid #eceeef;
		padding-left: 1rem;
	}

	.BlockListRef,
	.ClaimantRef,
	.EuropeanConventionLink,
	.InternationalConventionLink,
	.OrdinanceLink,
	.StatuteLink {
		font-style: italic;
	}
}