
.container {
  max-width: 1600px;
  width: 100%;

  &--slim {
    max-width: 960px;
  }

  &--caseReport {
    width: 61%;
  }

  &--genie {
    max-width: 1320px;
    background-color: white;
  }
}

@include media-breakpoint-only(xs) {
  .container--slim { max-width: 100% }
  .container--caseReport { width: 100% }
}
@include media-breakpoint-only(sm) {
  .container--slim { max-width: 90% }
  .container--caseReport { width: 100% }
}
@include media-breakpoint-only(md) {
  .container--slim { max-width: 80% }
}
@include media-breakpoint-only(lg) {
  .container--slim { max-width: 70% }
}
@include media-breakpoint-only(xl) {
  .container--slim { max-width: 65% }
}