
@import "../custom/tools/vars";

.feedback_content ul {
	list-style: none;
	padding-left: 11px;
}

.feedback_trigger{
	cursor: pointer;
	-webkit-border-top-left-radius: 0px;
	-moz-border-radius-topleft: 0px;
	border-top-left-radius: 0px;
	-webkit-border-bottom-left-radius: 0px;
	-moz-border-radius-bottomleft: 0px;
	border-bottom-left-radius: 0px;
	z-index: 2001;
	box-shadow: 0 0 3px 0 rgba(0,0,0,.3);
	color: #fff;
	background-color: $green;
	transition: margin 300ms ease;

	&:hover {
		background-color: $light-green;
	}
}

.feedback_trigger.left-top,
.feedback_trigger.left-bottom ,
.feedback_trigger.right-top,
.feedback_trigger.right-bottom {
	width: 30px;
	height: 100px;
}

.feedback_trigger.bottom-left{ 
	width: 100px;
	height: 40px;
}

.feedback_content {
	width: 380px;
	height: 300px;
	z-index: 2001;

	transition: margin 300ms ease;
	box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
	padding-top: 20px !important;
}

.feedback_content.email_present {
	height: 360px;
}

.feedback_content ul li {
	margin-right: 20px;
	margin-bottom: 20px;
}

.feedback_content label {
	display: inline-block;
}

.feedback_name {
	display: block;
	width: 100%;
	padding: 7px;
	border: $base-border;
	border-radius: $base-border-radius;

	&:focus {
		box-shadow: none;
		outline: none;
		border-color: $green;
	}
}

.feedback_email {
	display: block;
	width: 100%;
	padding: 7px;
	border: $base-border;
	border-radius: $base-border-radius;

	&:focus {
		box-shadow: none;
		outline: none;
		border-color: $green;
	}
}

.feedback_message {
	display: block;
	width: 100%;
	resize: none;
	padding: 7px;
	border: $base-border;
	border-radius: $base-border-radius;

	&:focus {
		box-shadow: none;
		outline: none;
		border-color: $green;
	}
}

.feedback_submit {
	float: right;
}

.feedback_trigger_text { 
	white-space: nowrap;
	position: absolute;
	top: 40px;
	letter-spacing: 2px;
	font-size: 17px;
}

.left-top .feedback_trigger_text , .left-bottom .feedback_trigger_text , .right-top .feedback_trigger_text , .right-bottom .feedback_trigger_text { 
	white-space: nowrap;
	position: absolute;
	top: 40px;
	letter-spacing: 2px;
	font-size: 17px;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	filter:none;
}

.fm_clean {
	background-color: #DDDDDD;
}

.fm_clean.feedback_trigger:hover {
	background-color: #CCCCCC;
}

.fm_clean button{
  	padding: 5px 10px;
	display: inline;
	background: #777;
	border: none;
	color: #fff;
	cursor: pointer;
	font-weight: bold;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	text-shadow: 1px 1px #666;
}

.fm_clean button:hover {
	background-color: #9C9A9A;
}

.fm_clean .feedback_trigger_text { 
	right: -25px;
}

.fm_clean .feedback_title {
	padding-top: 5px;	
}

.fm_clean .feedback_title span {
	margin-left: 10px;
}

.feedback_content.fm_clean.feedback_content_closed.left-top, .feedback_content.fm_clean.feedback_content_closed.left-bottom{
	margin-left: -380px;
}

.feedback_content.fm_clean.left-top, .feedback_content.fm_clean.left-bottom{
	margin-left: 0px;
	-webkit-border-bottom-right-radius: 6px;
	-moz-border-bottom-right-radius: 6px;
	border-bottom-right-radius: 6px;
	-webkit-border-top-right-radius: 6px;
	-moz-border-top-right-radius: 6px;
	border-top-right-radius: 6px;
}

.feedback_trigger.fm_clean.feedback_trigger_closed.left-top, .feedback_trigger.fm_clean.feedback_trigger_closed.left-bottom{
	margin-left: 0px;
}

.feedback_trigger.fm_clean.left-top, .feedback_trigger.fm_clean.left-bottom{
	margin-left: 380px;
	-webkit-border-bottom-right-radius: 6px;
	-moz-border-bottom-right-radius: 6px;
	border-bottom-right-radius: 6px;
	-webkit-border-top-right-radius: 6px;
	-moz-border-top-right-radius: 6px;
	border-top-right-radius: 6px;
}

.feedback_content.fm_jquery.feedback_content_closed.left-top, .feedback_content.fm_jquery.feedback_content_closed.left-bottom {
	margin-left: -382px;
}

.feedback_content.fm_jquery.left-top, .feedback_content.fm_jquery.left-bottom {
	margin-left: 0px;
}

.feedback_trigger.fm_jquery.feedback_trigger_closed.left-top, .feedback_trigger.fm_jquery.feedback_trigger_closed.left-bottom {
	margin-left: 0px;
}

.feedback_trigger.fm_jquery.left-top, .feedback_trigger.fm_jquery.left-bottom {
	margin-left: 382px;
	-webkit-border-bottom-right-radius: 6px;
	-moz-border-bottom-right-radius: 6px;
	border-bottom-right-radius: 6px;
	-webkit-border-top-right-radius: 6px;
	-moz-border-top-right-radius: 6px;
	border-top-right-radius: 6px;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-bottom-left-radius: 0;
	border-bottom-left-radius: 0;
	-webkit-border-top-left-radius: 0;
	-moz-border-top-left-radius: 0;
	border-top-left-radius: 0;
}

.fm_jquery .feedback_trigger_text { 
	right: -20px;
}

.fm_jquery.feedback_content {
	padding: 0px;
	width: 380px;
	height: 340px;
}

.fm_jquery.feedback_content.email_present {
	height: 390px;
}


.fm_jquery.feedback_trigger{
	padding: 0px;
	border: 0px;
}

.feedback_content.fm_bootstrap.feedback_content_closed.left-top, .feedback_content.fm_bootstrap.feedback_content_closed.left-bottom {
	margin-left: -380px;
}

.feedback_content.fm_bootstrap.left-top, .feedback_content.fm_bootstrap.left-bottom {
	margin-left: 0px;
}

.feedback_trigger.fm_bootstrap.feedback_trigger_closed.left-top, .feedback_trigger.fm_bootstrap.feedback_trigger_closed.left-bottom {
	margin-left: 0px;
}

.feedback_trigger.fm_bootstrap.left-top, .feedback_trigger.fm_bootstrap.left-bottom {
	margin-left: 380px;
}


.fm_bootstrap .feedback_trigger_text { 
	right: -21px;
	letter-spacing: 1px;
}

.fm_bootstrap.hero-unit {
	padding:0px;
}

.fm_bootstrap.hero-unit ul {
	margin-left:15px;
}

.fm_bootstrap .feedback_title {
	margin-bottom: 10px;
	font-weight: $font-weight-semibold;
}

.fm_bootstrap .feedback_title span {
	margin-left: 15px;
}

.fm_bootstrap .feedback_trigger_text {
	top: 40px;
	font-size: 1.4rem !important;
}

.fm_bootstrap.feedback_content ul {
	padding: 0px;
}

.fm_bootstrap.feedback_content {
	height: 340px;
}

.fm_bootstrap.feedback_content.email_present {
	height: 430px;
}

.required_asterisk {
	color: red;
}

.feedback_content.radio_button_list_present {
	height: 380px;
}

.feedback_content.email_present.radio_button_list_present {
	height: 440px;
}

.radio_button_wrapper{
    display:inline-block;
    text-align:center;
	margin-right:35px;
}
.feedback_content .radio_button_wrapper label {
    display:block;
}

.radio_button_list_title_wrapper{
	margin-bottom: 10px;
}

.fm_jquery.feedback_content.radio_button_list_present {
	height: 430px;
}

.fm_bootstrap.feedback_content.radio_button_list_present {
	height: 470px;
}

.fm_jquery.feedback_content.email_present.radio_button_list_present {
	height: 500px;
}

.fm_bootstrap.feedback_content.email_present.radio_button_list_present {
	height: 550px;
}

@media \0screen {
	.fm_clean .feedback_trigger_text, .fm_jquery .feedback_trigger_text, .fm_bootstrap .feedback_trigger_text {
		right:-70px;
		width:100px;
		margin-top:-45px;
		filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.00000000, M12=1.00000000, M21=-1.00000000, M22=0.00000000,sizingMethod='auto expand');
	}
	.fm_bootstrap .feedback_trigger_text {
		right:-65px;
		margin-top:-40px;
	}
}

.feedback_trigger.left-top {
	position: fixed;
	top: 100px;
	left: 0;
}

.feedback_content.left-top {
	position: fixed;
	top: 50px;
	left: 0;
}

.feedback_trigger.left-bottom {
	position: fixed;
	bottom: 100px;
	left: 0;
}

.feedback_content.left-bottom {
	position: fixed;
	bottom: 50px;
	left: 0;
}

.feedback_trigger.right-top {
	position: fixed;
	top: 100px;
	right: 0;
}

.feedback_content.right-top {
	position: fixed;
	top: 50px;
	right: 0;
}

.feedback_trigger.right-bottom {
	position: fixed;
	bottom: 100px;
	right: 0;
}

.feedback_content.right-bottom {
	position: fixed;
	bottom: 50px;
	right: 0;
}

.feedback_content.fm_clean.feedback_content_closed.right-top, .feedback_content.fm_clean.feedback_content_closed.right-bottom{
	margin-right: -380px;
}

.feedback_content.fm_clean.right-top, .feedback_content.fm_clean.right-bottom{
	margin-right: 0px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
	-webkit-border-top-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	border-top-left-radius: 6px;
}

.feedback_content.fm_jquery.feedback_content_closed.right-top, .feedback_content.fm_jquery.feedback_content_closed.right-bottom {
	margin-right: -382px;
}

.feedback_content.fm_jquery.right-top, .feedback_content.fm_jquery.right-bottom {
	margin-right: 0px;
}

.feedback_content.fm_bootstrap.feedback_content_closed.right-top, .feedback_content.fm_bootstrap.feedback_content_closed.right-bottom {
	margin-right: -380px;
}

.feedback_content.fm_bootstrap.right-top, .feedback_content.fm_bootstrap.right-bottom {
	margin-right: 0px;
	
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
	
	-webkit-border-top-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	border-top-left-radius: 6px;
	
	-webkit-border-bottom-right-radius: 0;
	-moz-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0;
	
	-webkit-border-top-right-radius: 0;
	-moz-border-top-right-radius: 0;
	border-top-right-radius: 0;
}


.feedback_trigger.fm_clean.feedback_trigger_closed.right-top, .feedback_trigger.fm_clean.feedback_trigger_closed.right-bottom{
	margin-right: 0px;
}

.feedback_trigger.fm_clean.right-top, .feedback_trigger.fm_clean.right-bottom{
	margin-right: 380px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
	-webkit-border-top-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	border-top-left-radius: 6px;
}

.feedback_trigger.fm_jquery.feedback_trigger_closed.right-top, .feedback_trigger.fm_jquery.feedback_trigger_closed.right-bottom {
	margin-right: 0px;
}

.feedback_trigger.fm_jquery.right-top, .feedback_trigger.fm_jquery.right-bottom {
	margin-right: 382px;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0;
	-webkit-border-top-right-radius: 0;
	-moz-border-top-right-radius: 0;
	border-top-right-radius: 0;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
	-webkit-border-top-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	border-top-left-radius: 6px;
}

.feedback_trigger.fm_bootstrap.feedback_trigger_closed.right-top, .feedback_trigger.fm_bootstrap.feedback_trigger_closed.right-bottom {
	margin-right: 0px;
}

.feedback_trigger.fm_bootstrap.right-top, .feedback_trigger.fm_bootstrap.right-bottom {
	margin-right: 380px;
	
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
	
	-webkit-border-top-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	border-top-left-radius: 6px;
	
	-webkit-border-bottom-right-radius: 0;
	-moz-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0;
	
	-webkit-border-top-right-radius: 0;
	-moz-border-top-right-radius: 0;
	border-top-right-radius: 0;
}

.feedback_me_frame {
	border: none; 
	overflow: auto; 
	height: 90%;
	width: 98%;
}

.feedback-delayed-dlg {
	position: fixed;
	width: 250px;
	height: 100px;
	top: 50%;
	left: 50%;
	vertical-align: middle;
    text-align: center;
	margin-left: -125px;
	margin-top: -50px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
}

.feedback-delayed-dlg.success {
	background-color: #2ECC40;
}

.feedback-delayed-dlg.fail {
	background-color: #FF4136;
}


.feedback-dlg-close {
	position: absolute;
	right: 0;
	padding: 1px;
	background: #FFFFFF;
	cursor: pointer;
}

.feedback-sucess-fail-message-inner {
	position: fixed;
	width: 200px;
	height: 50px;
	background-color: #FFFFFF;
	top: 50%;
	left: 50%;
	vertical-align: middle;
    text-align: center;
	margin-left: -100px;
	margin-top: -25px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	line-height:50px;
}

.feedback-sucess-fail-message-inner > span{
	display: inline-block;
    vertical-align: middle;
    line-height: 1em;
}

.feedback-sucess-message, .feedback-fail-message {
	display: inline-block;
	width: 200px;
	height: 50px;
}