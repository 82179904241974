.modal {
  .modal-header {
    background-color: #555; color: white;
    .modal-title {
      font-size: 1.8rem;
    }
    .btn-close {
      opacity: 1;

      &:hover {
        background-color: $gray;
      }

      &:focus,
      &:active {
        outline: 1px solid $light-gray;
        box-shadow: none;
      }
    }
  }
}