.free-trial {
  background-color: white; margin: 0 0 10px 0; border: #b8b8b8 4px solid; padding: 35px 0 30px 0; text-align: center; font-weight: normal;

  .title { font-size: 23pt; line-height: 23pt; }
  a, a:link, a:visited, a:hover, a:active { display: block; min-width: 80%; margin: 18px 0 0 10%; padding: 2px 0 2px 0; color: white; font-size: 10pt; text-decoration: none; font-weight: 500; background-color: #55b848; width: 80px }
  .or { margin-top: 18px; margin-bottom: 18px; font-weight: 500; font-size: 16pt }
  .parenthetical-catchy { font-weight: 200; font-size: 18px }
  .monetary-value { font-weight: 400; font-size: 45pt; line-height: 45pt; }
  .a-month { font-size: 18pt; line-height: 18pt; }
  .try-free { font-size: 35pt; line-height: 35pt; }
  .for-7-days { font-size: 24px }
}