@font-face {
  font-family: 'ICLR Icon Font';
  src: url('assets/fonts/custom-icons/iclr-icons.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/custom-icons/iclr-icons.woff') format('woff'),
  url('assets/fonts/custom-icons/iclr-icons.ttf') format('truetype'),
  url('assets/fonts/custom-icons/iclr-icons.svg#iclr-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: "ICLR Icon Font";
  font-size: 50px
}

.icon.icon-help-circle { font-size: 10pt !important; color: $basic-icon-color; vertical-align: middle; }
.icon.icon-help-circle.small { font-size: 8pt !important; vertical-align: top; }

.icon-bookmark:before { content: '\e000'; }
.icon-check:before { content: '\e001'; }
.icon-chevron-square:before { content: '\e002'; }
.icon-clock:before { content: '\e004'; }
.icon-contract:before { content: '\e005'; }
.icon-copy:before { content: '\e006'; }
.icon-document:before { content: '\e007'; }
.icon-expand:before { content: '\e009'; }
.icon-help-circle:before { content: '\e00a'; }
.icon-index-applied:before { content: '\e00b'; }
.icon-index-distinguished:before { content: '\e01a'; }
.icon-index-considered:before { content: '\e00c'; }
.icon-index-not:before { content: '\e00d'; }
.icon-message:before { content: '\e00e'; }
.icon-return:before { content: '\e00f'; }
.icon-search:before { content: '\e010'; }
.icon-share:before { content: '\e011'; }
.icon-envelope:before { content: '\e013'; }
.icon-chevron-down:before { content: '\e014'; }
.icon-chevron-left:before { content: '\e015'; }
.icon-chevron-right:before { content: '\e016'; }
.icon-chevron-up:before { content: '\e017'; }
.icon-print:before { content: '\e01b'; }
.icon-pdf:before { content: '\e01c'; }
.icon-twitter:before { content: '\e019'; }