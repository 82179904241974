html,
body {
	color: $base-color;
}

body {
	background-color: $light-gray;
}

a {
	outline: none;
	text-decoration: none;
	color: $basic-link-color;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		color: darken($basic-link-color, 5%);
		outline: none;
		text-decoration: underline;
	}

	/*&[href*="http"]{
		color: $blue;

		&:hover,
		&:active,
		&:focus {
			color: darken($blue, 5%);
		}
	}*/
}

img {
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
}

b,
strong {
	font-weight: $font-weight-semibold;
}

var {
	font-style: normal;
}

input,
button {
	outline: none;

	&:focus {
		outline: none;
	}
}

// Lists

ol {
	margin: 0;
	padding: 0;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

dl {
	margin-bottom: $small-spacing;

	dt {
		font-weight: 800;
		margin-top: $small-spacing;
	}

	dd {
		margin: 0;
	}
}

// Tables

table {
	border-collapse: collapse;
	font-feature-settings: "kern", "liga", "tnum";
	margin: $small-spacing 0;
	table-layout: fixed;
	width: 100%;
}

th {
	font-weight: $font-weight-semibold;
	border-bottom: $base-border;
	padding: $small-spacing 0;
	text-align: left;
}

td {
	border-bottom: $base-border;
	padding: $small-spacing 0;
}

tr,
td,
th {
	vertical-align: middle;
}

#index-card {
	top: 152px;
	height: 0;
	overflow: auto;
	left: 0;
	right: 0;

	position: fixed;
	min-height: 0;
	z-index: 10;

	margin: 0 10px 10px 10px;

	-webkit-box-shadow: 0px 10px 12px 0px rgba(156,156,156,1);
	-moz-box-shadow: 0px 10px 12px 0px rgba(156,156,156,1);
	box-shadow: 0px 10px 12px 0px rgba(156,156,156,1);

	border-top-width: 0;

	.layout {
		padding-top: 20px;
	}
}

.cookies {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
	padding: 10px 20px 0;
	color: #fff;
	text-align: center;
	z-index: 3;
	
	p {
		margin: 0;
	
		.btn-thin {
			vertical-align:baseline;
			font-size:12px; 
		}
	}
}

.watermark { display: none; }
.print-only { display: none; }
