
.dropdown-menu {
	min-width: 150px;
	font-size: 1.2rem;

	@include respond-to(small-screens){
		margin: 0;
		border: none;
	}

	&--back-nav {
		padding: 0;

		@include media-breakpoint-up(xl) {
			min-width: 400px;
		}
	}
}

.dropdown-header {
	font-size: 14px;
}

.datepicker {
	max-width: 223px;
	text-align: center;
	padding-top: 0;
	padding-bottom: 0;

	table {
		margin-top: 0;

		tr {
			.datepicker-switch, .prev, .next, td.day {
				cursor: pointer;
			}

			th, td {
				text-align: center;

				span.month, span.year, span.decade, span.century {
					display: block;
					cursor: pointer;
				}

			}

		}
	}
}

.datepicker table tr td.active,
.datepicker table tr td.active.highlighted,
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
	background-color: $green !important;
	border: none;
}