.pagination {
	font-size: $base-font-size;

	.page-item {
		.page-link {
			height: 30px;
			color: $base-color;
			padding: 0.5rem 1rem;
			line-height: 1.5;
			border-color: $aluminum;
		}

		&.disabled {
			.page-link {
				color: $gray;
			}
		}

		&.active {
			.page-link {
				background-color: $base-color;
				border-color: $base-color;
				color: #fff;
			}
		}

		&:first-child {
			.page-link {
				border-radius: $base-border-radius 0 0 $base-border-radius;
			}
		}

		&:last-child {
			.page-link {
				border-radius: 0 $base-border-radius $base-border-radius 0;
			}
		}
	}

	.icon {
		font-size: $base-font-size;
		margin-top: -1px;
		display: block;
	}
}