.page-footer {
	display: flex;
	align-items: center;
	background-color: $aluminum;
	padding: 0 5px 0 5px;
	min-height: $footer-height;
	font-size: 1.2rem;

	&__links {
		white-space: nowrap;

		@include respond-to(small-screens) {
			padding-top: $basic-vertical-offset;
		}
	}

	&__link-item {
		//white-space: nowrap;
		color: $base-color;
		//font-size: 1.2rem;
	}

}

.standard-footer-view.page-footer {
	.layout--sidebars ~ & {
		@include respond-to(medium-screens){
			padding-left: calc(100vw * 0.25);
			//padding-right: calc(100vw * 0.25);
		}

		@include respond-to(wide-screens) {
			padding-left: calc(100vw * 0.166667);
			//padding-right: calc(100vw * 0.166667);
		}
	}
}

.document-footer-view.page-footer {
	.layout--sidebars ~ & {
		@include respond-to(medium-screens){
			padding-left: calc(100vw * 0.20);
			padding-right: calc(100vw * 0.20);
		}
	}
}

.legislation-footer-view.page-footer {
	.layout--sidebars ~ & {
		@include respond-to(medium-screens){
			padding-left: calc(100vw * 0.25);
			padding-right: calc(100vw * 0.20);
		}
	}
}

// Tweak cookie notice links
.cookies p {
	a {
		color: white;
		text-decoration: underline;
	}
	a.btn, a.btn-thin {
		background: white;
		color: $green;
	}
}