.sidebar {
	overflow-y: auto;
	width: 100%;

	&--fixed {
		position: fixed;
		bottom: 0;

		@include respond-to(small-screens){
			position: relative !important;
		}

		.layout--subnav & {
			top: calc(#{$layout-padding-with-sub-nav} - #{$basic-vertical-offset});

			@include respond-to(small-screens){
				max-height: none;
				margin-bottom: 20px;
				top: 0;
			}
		}
	}

	&--fixed-with-full-width-recalc {

		@include respond-to(small-screens){
			position: relative;
		}

		@include respond-to(medium-screens){
			width: calc(100% - 15px);
		}

		@include respond-to(wide-screens) {
			max-width: calc((550px) - 15px);
			width: calc(100% - 15px);
		}
	}

	&--fixed-with-large-width-recalc {

      @include respond-to(small-screens){
        position: relative;
      }

      @include respond-to(medium-screens){
        width: calc((100vw * 0.25) - 65px);
      }

      @include respond-to(wide-screens) {
        max-width: calc((1600px * 0.166667) - 15px);
        width: calc((100vw * 0.166667) - 15px);
      }
	}

	&--fixed-with-medium-width-recalc {
      @include respond-to(small-screens){
        position: relative;
      }

      @include respond-to(medium-screens){
        width: calc((100vw * 0.25) - 15px);
      }
    }

	&--fixed-with-medium-2-width-recalc {
		@include respond-to(small-screens){
			position: relative;
		}

		@include respond-to(medium-screens){
			width: calc((80vw * 0.25) - 15px);
		}
	}

	&--default {
		@include content-item;
		background-color: #fff;
		padding: 15px;
	}

	&--transparent {}

	&--left {
		&.sidebar--fixed {
			left: 0;
		}
	}

	&--right {
		&.sidebar--fixed {
			right: 0;
		}
	}

	&__section {
		margin-bottom: 20px;

		a.selected {
			color: $basic-link-color;
		}
	}

	&__list {
		.sidebar__list {
			padding-left: 10px;
			margin-top: 10px;
		}
	}

	&__list-item {
		margin-bottom: 10px;
		margin-top: 10px;

		.number {
			padding-right: 10px;
		}
	}

	&__list-item-link {
		color: $base-color;
		position: relative;
		display: flex;
		line-height: 20px;
		align-items: baseline;

		&:hover {
			.badge {
				background-color: $hover-link-color;
				color: #fff;
			}
		}

		&.active {
			color: $hover-link-color;

			&:before {
				content: '\e016';
				font-family: "ICLR Icon Font";
				position: absolute;
				left: -12px;
				top: 1px;
			}

			.badge {
				background-color: $hover-link-color;
				color: #fff;
			}
		}

		.badge {
			margin-left: 5px;
		}
	}

	.card {
		margin-left: 0;
		margin-right: 0;
		height: auto;

		p > .linkTypeHeading {
			margin-top: 0px;
			margin-bottom: 5px;
			font-weight: 500;
			color: $mid-gray;
		}

		p > .linkTypeHeading ~ .linkTypeHeading {
			margin-top: 10px;
		}
	}
}

.sidebar__list-item-link:hover {

	color: $black;
}

section.legislation-toc {

    >ul {
      margin-left: 10px;
    }

	ul ul {
		margin-left: 15px;
	}

	.icon {
		font-size: 10pt;
		margin: 0 0 0 -15px;
		float: left;
		cursor: pointer;
		color: #898;
	}

	li.collapsed > span.icon:before {
		content: '\e009';
	}

	li.expanded > span.icon:before {
		content: '\e005';
	}

	li span.icon:hover {
		color: #565;
	}

	li.collapsed ul {
		display: none;
	}

	h4 .toc-buttons {
		float: right;
		span {
			font-family: "ICLR Icon Font", monospace;
			cursor: pointer;
		}

		span#expandAllToc:before {
			content: '\e009';
		}

		span#collapseAllToc:before {
			content: '\e005';
		}
	}
}

#sidebar-toc {
	padding: 0;
	flex-direction: column;
	align-items: stretch;

	> h4 {
		margin-bottom: 0;
	}

	.nav-link {
		padding: 0;
	}

	.icon {
		font-size: 10pt;
		margin: 0 0 0 -15px;
		float: left;
		cursor: pointer;
		color: #898;
	}

	li.collapsed > span.icon:before {
		content: '\e009';
	}

	li.expanded > span.icon:before {
		content: '\e005';
	}

	li span.icon:hover {
		color: #565;
	}

    li.collapsed ul {
      display: none;
    }

	ul ul {
		margin-left: 15px;
	}

	h4 .toc-buttons {
		float: right;
		span {
			font-family: "ICLR Icon Font", monospace;
			cursor: pointer;
		}

		span#expandAllToc:before {
			content: '\e009';
		}

		span#collapseAllToc:before {
			content: '\e005';
		}
	}

	li.expanded > .sidebar__list-item-link.active::before, li.collapsed > .sidebar__list-item-link.active::before {
		content: "";
	}

	a:focus { color: inherit; }
	a.active:focus { color: $hover-link-color; }
}

.searchFilterSection {
	transition: none;
	flex-grow: 0;

	.sidebar__list {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.sidebar__list-item {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

@include media-breakpoint-up(md) {
	#searchFacetsHeader .heading {
		display: none;
		visibility: hidden;
	}
}

#topicsList {
	transition: none;
}
