#progressBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    max-width: 600px;

    .genie_logo {
      float:left;

      svg {
        fill: $basic-icon-color;
      }
    }

    .message {
      vertical-align: super
    }
  }

  > #progressBoxList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > .step-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5rem;
      padding: 0 1rem 0 0.5rem;
      border-radius: $base-border-radius;
      border: 1px solid transparent;

      &.Pending { background-color: #eceeef; color: $dark-gray }
      &.Running { border-color: $dark-gray }
      &.Completed { background-color: #f1e7fa; }
      &.Failed { background-color: #ffe0e0; }
      &.PreviousFailed { background-color: #fae8d0; }
    }

    i {
      color: #9c9c9c;
    }

    .done {
      color: $purple400;
    }

    .active {
      color: $black;
    }

    .error {
      color: #c72a2a;
    }

    li span {
      font-size: 16px;
    }
  }

  #cog-container {
    color: $purple400;
    display: flex;
    flex-direction: column;
    height: 5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-block-end: 2rem;

    > #cog-1 {
      font-size: 5rem;
    }

    > #cog-2, #cog-3 {
      font-size: 2.25rem;
    }
  }
}

#errorBox {
  margin-block-start: 2rem;

  > p {
    margin-block-end: 1rem;
    text-align: center;
  }
  .spinner-central > i {
    font-size: 5rem;
    color: red;
  }
}

.spin-clockwise{
  animation: spin-clockwise 1.4s infinite linear;
}

.spin-anticlockwise{
  animation: spin-anticlockwise 2.5s infinite linear;
}

@keyframes spin-clockwise {
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin-anticlockwise {
  to {
    transform: rotate(-359deg);
  }
}
