@import '../../custom/tools/vars';

#navbar-header {
	min-height: 100%;
	background-color: $black;
	color: white;
	z-index: 101;
	min-height: 66px;
	position: sticky;
	top: 0;
	padding: 0.5rem 2rem;

	> .navbar-brand > .navbar-brand-logo {
		max-height: 2.3rem;
	}

	> .navbar-toggler {
		font-size: 2rem;
		color: white;
	}

	> .navbar-collapse {
		max-width: 100%;
	}

	.nav-link {
		color: white;

		.icon {
			font-size: 14px;
		}
	}

	.dropdown-toggle:after {
		display: none;
	}

	.dropdown-menu {
		left: unset;
		right: 0;
		background-color: white;
		top: calc(100% + 2px);
	}

	.dropdown-item {
		padding: 1.3rem 1.5rem;
		font-size: 1.4rem;
		white-space: pre-wrap;

		&:hover {
			background-color: $purple;
			color: white;
			text-decoration: none;
		}
	}
}

#menuNavItem {
	position: static;
}

#header-menu {
	padding: 1.5rem;

	@include respond-to(medium-screens){
		&.show {
			display: flex;
		}
		margin-left: 20%;
		margin-top: -1.5rem;
		border-color: #E6E7E8;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
	}
}

.header-menu-section {
	padding-bottom: 1.5rem;

	@include respond-to(medium-screens){
		width: 33%;
		padding: 1.5rem;
	}

	> .heading {
		font-weight: 500;
		border-bottom: 1px solid $aluminum;
		padding-bottom: .2em;
		margin-bottom: 1em;
		font-size: 1.2rem;
		line-height: 1.4;
	}

	> .header-menu-item {
		border-bottom: 1px solid $aluminum;
		padding: 0.7rem 1.5rem;
		font-size: 1.4rem;
		display: block;
		color: $black;

		&:hover {
			background-color: $purple;
			color: white;
			text-decoration: none;
		}
	}

	> #blogItems {

		max-height: 26rem;
		overflow-y: auto;

		> .blog-link {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			color: $black;
			font-size: 1.3rem;
			margin-bottom: 10px;

			&:hover {
				color: $purple;
			}
		}
	}

	> .header-contact-link {
		display: block;
		color: $gray;
		font-size: 1.4rem;

		> .icon {
			font-size: 1.4rem;
		}

		&:hover {
			color: $purple;
		}
	}
}

#nav-bar-query {
	margin-top: 1rem;
	width: 100%;

	@include respond-to(medium-screens) {
		margin-top: 0;
		margin-left: 5%;
	}

	.search-form__link-item {
		color: white;
	}

	.icon.icon-search {
		top: 8px;
	}

	.twitter-typeahead {
		margin-top: 2px;
	}

	.search-form__button {
		height: 34px;
		padding-top: 8px;
	}
}

nav {
	.twitter-typeahead {
		.tt-menu {
			margin-left: -28px;
			margin-top: 2px;
			border-radius: 0.64rem;
		}
	}
}

nav.iclr_secondary_navigation .twitter-typeahead .tt-menu { margin-left: 0 }

#user-menu-avatar {
	max-height: 2rem;
}

.nav-link {
	position: relative;
}

.nav-item.dropdown.show .nav-link {

	&:before {
		border-style: solid;
		border-width: 0 6px 6px 6px;
		content: '';
		position: absolute;
		border-color: transparent transparent #fff transparent;
		top: 2.9rem;
		left: 7px;
	}

	&#user-menu-btn:before {
		left: 9px;
	}
}