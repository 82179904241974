
.content-section {
  @include content-item;
  padding: 20px;
}

.content-block {
  padding: 20px 0;
  border-bottom: $base-border;

  p:last-child {
    margin-bottom: 0;
  }
}

.login-column {
	max-width: 320px;
	margin: 0 auto;
}